.imageEditButton {
    position: absolute;
    right: 10px;
    background: white;
    border: none;
    border-radius: 100px;
    width: 35px;
    height: 35px;
    top: 10px;
    cursor: pointer;
}


.setting_section {
    border-top: solid 1px gray;
    border-bottom: solid 1px gray;
    padding: 20px 0px;

    .setting_section_header {
        font-size: 21px;
        font-family: "Roboto", "Helvetica", "Arial", sans-serif;
        padding-bottom: 12px;
        font-weight: bold;
    }
}